@font-face {
	font-family: "geometria";
	font-style: normal;
	src: url("../src/font/Geometria.ttf") format("truetype");
}

@font-face {
	font-family: "geometriabold";
	font-style: normal;
	src: url("../src/font/Geometria-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "geometriaextrabold";
	font-style: normal;
	src: url("../src/font/Geometria-ExtraBold.ttf") format("truetype");
}

@font-face {
	font-family: "calibri";
	font-style: normal;
	src: url("../src/font/calibri-regular.ttf") format("truetype");
}

@font-face {
	font-family: "calibribold";
	font-style: normal;
	src: url("../src/font/calibri-bold.ttf") format("truetype");
}

@font-face {
	font-family: "calibriitalic";
	font-style: normal;
	src: url("../src/font/calibri-italic.ttf") format("truetype");
}

html,
body {
	margin: 0;
	padding: 0;
	width: 100%;
	overflow-x: hidden;
	/* Esto evita el scroll horizontal */
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	overflow-x: hidden;
	font-family: "calibri";
}

body p {
	font-family: "calibri";
}

body h2 {
	font-family: "calibri";
}

body h3 {
	font-family: "calibri";
}

body h4 {
	font-family: "calibri";
}

body h5 {
	font-family: "calibri";
}

body h6 {
	font-family: "calibri";
}

body h7 {
	font-family: "calibri";
}

body label {
	font-family: "calibri";
}

body label b {
	font-family: "calibriextrabold";
	color: black;
}

body input {
	font-family: "calibri";
}

body select {
	font-family: "calibri";
}

body p b {
	font-family: "calibribold";
	color: black;
}

/* body ul li{ */
/* font-family: 'calibriextrabold'; */
/* color: black; */
/* } */

body button {
	font-family: "calibri";
	color: black;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
	monospace;
}

#footer1 {
	background-color: #000 !important;
	position: relative;
	width: 100%;
}

#footer2 {
	background-color: #000 !important;
}

#footer3 {
	background-color: #000 !important;
}

/* MODIFIED_BY@KH start */
.footer-paragraph {
	margin-bottom: 0;
}

/* MODIFIED_BY@KH end */

.maps {
	height: 500px;
	width: 300px;
}

.borderInputSearch input {
	border: none !important;
	width: 100% !important;
	font-size: 9pt !important;
}

.borderInputSearch2 input {
	border: none !important;
	width: 100% !important;
}

.alignControles {
	text-align: center;
}

.colorTextoAnuncio {
	color: black;
	font-size: 10pt;
	text-align: left;
}

.borderrecuadroanuncios {
	border-bottom: 8px solid white;
	border-radius: 5px;
	background-color: aliceblue;
}

.btnIrAnuncioMapa {
	width: 100px;
	height: 50px;
	background-color: darkslateblue;
	border-radius: 8px;
	color: white;
	cursor: pointer;
}

h1,
p {
	font-family: Lato;
}

#mapResponsive {
	position: relative;
	top: 20px;
	height: 820px;
	width: 100%;
	background: #007bff;
}

#map2 {
	float: left;
	width: 100%;
	height: 300px;
	overflow: hidden;
	border-radius: 10px;
}

#mapaDetalle {
	float: right;
	width: 100%;
	height: calc(11em + 11vw);
	overflow: hidden;
	border-radius: 10px;
}

#panel {
	overflow: hidden;
}

/*input[type=button] {
display: inline-block;
margin: 0.1em 0;
border: 0.16em solid rgba(255, 255, 255, 0);
width: 20%;
box-sizing: border-box;
text-decoration: none;
font-family: 'Roboto', sans-serif;
font-weight: 300;
color: black;
text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
text-align: center;
transition: all 0.2s;
background-color: #FFE521;
font-size: 10pt;
}*/

	.popupmenu {
		height: calc(280px + 2vmin);
		background-color: white;
		width: calc(7em + 7vw);
		z-index: 4;
		position: absolute;
		top: 7%;
		right: 11%;
		border-radius: 10px;
		font-size: 10pt;
		display: none;
	}

	.popupmenu.activo {
		height: calc(280px + 2vmin);
		background-color: white;
		width: calc(190px + 2vmin);
		z-index: 4;
		top: 7%;
		right: 10%;
		border-radius: 10px;
		font-size: 10pt;
		display: block;
		z-index: 10;
	}

	.popupcarac {
		height: calc(300px + 2vmin);
		background-color: white;
		width: calc(780px + 2vmin);
		z-index: 4;
		position: absolute;
		right: 0;
		top: 20%;
		border-radius: 10px;
		font-size: 10pt;
		display: none;
	}

	.popupcarac.activo {
		height: calc(300px + 2vmin);
		background-color: white;
		width: calc(780px + 2vmin);
		z-index: 4;
		position: absolute;
		right: 0;
		top: 20%;
		border-radius: 10px;
		font-size: 10pt;
		display: block;
		-moz-box-shadow: 6px 6px 6px #9c9c9c;
		-webkit-box-shadow: 6px 6px 6px #9c9c9c;
		box-shadow: 6px 6px 6px #9c9c9c;
		border-top-right-radius: 10px;
		border-bottom-left-radius: 10px;
	}

	#registro {
		display: none;
	}

	.btncaracteristicasregistro {
		border: none;
		width: 100%;
		height: 50px;
		align-items: center;
		cursor: pointer;
	}

	.btncaracteristicas {
		border: none;
		width: 100%;
		height: 50px;
		align-items: center;
	}

	.btncaracteristicas2 {
		border: none;
		width: 100%;
		height: 50px;
		align-items: center;
	}

	.btncaracteristicasanuncio {
		border: none;
		width: 100%;
		height: 30px;
		transform: translateX(-10px);
	}

	.btncaracteristicas.activo {
		border: 3px solid #ffe521;
		padding: 0 0 0 0;
		border-radius: 10px;
	}

	.btncaracteristicas-activo {
		border: 3px solid #ffe521;
		padding: 0 0 0 0;
		border-radius: 10px;
	}

	.btncaracteristicas2.activo {
		border: 3px solid #ffe521;
		padding: 0 0 0 0;
		border-radius: 10px;
	}

	.btnperfil {
		border: none;
		width: 100%;
		height: 100%;
		align-items: center;
	}

	.btnperfil2 {
		border: none;
		width: 100%;
		height: 100%;
		align-items: center;
		filter: grayscale(100%);
	}

	.btnperfil.activo {
		border: 1px solid #ffe521;
		padding: 0 0 0 0;
		border-radius: 10px;
	}

	.registrocarac_ul {
		display: flex;
		gap: 1%;
		list-style: none;
		padding: 0 0 0 0;
		flex-wrap: wrap;
		padding: 0 0 0 10px;
	}

	.registrocarac_ul li {
		float: left;
		padding: 0 0 0 0;
		position: relative;
		width: 20%;
		cursor: pointer;
	}

	.popupcarac_ul {
		list-style: none;
		margin: 0;
		padding: 0 0 0 0;
		display: block;
		height: calc(280px + 2vmin);
	}

	.popupcarac_ul li {
		float: left;
		padding: 0 0 0 0;
		position: relative;
		max-height: 50%;
		width: 20%;
		cursor: pointer;
	}

	.popupmenu_ul {
		list-style: none;
		margin: 0;
		padding: 0 15px 0 15px;
		text-align: left;
		z-index: 10;
	}

	.popupmenu_ul li {
		width: 100%;
		padding-top: 25px;
		border-bottom: 1px solid #d7d7d7;
		cursor: pointer;
		font-size: 10pt;
		z-index: 10;
	}

	.interesados_ul {
		list-style: none;
		margin: 0;
		padding: 0 15px 0 15px;
		text-align: left;
		min-height: 200px;
	}

	.interesados_ul_li {
		width: 100%;
		padding-top: 25px;
		border-bottom: 1px solid #9c9c9c;
		background-color: #9c9c9c;
		cursor: pointer;
		overflow: hidden;
		border-spacing: 0 !important;
	}

	.interesados_ul_li2 {
		width: 100%;
		padding-top: 25px;
		border-bottom: 1px solid #d7d7d7;
		background-color: #d7d7d7;
		cursor: pointer;
		overflow: hidden;
		border-spacing: 0 !important;
	}

	.popupmenu2 {
		height: calc(170px + 2vw);
		background-color: white;
		width: calc(180px + 2vmin);
		/* z-index: 10 !important; */
		z-index: 101 !important;
		position: absolute;
		top: 7%;
		left: 83%;
		border-radius: 10px;
		font-size: 10pt;
		display: none;
	}

	.popupmenu2.activo {
		height: calc(170px + 2vw);
		background-color: white;
		width: calc(180px + 2vmin);
		position: absolute;
		z-index: 10;
		top: 7%;
		left: 83%;
		border-radius: 10px;
		font-size: 10pt;
		display: block;
	}

	.popupmenu2_ul {
		list-style: none;
		margin: 0;
		padding: 0 25px 0 25px;
		text-align: left;
		height: 100%;
	}

	.popupmenu2_ul li {
		width: 100%;
		padding-top: 20px;
		border-bottom: 1px solid #d7d7d7;
		cursor: pointer;
		z-index: 10 !important;
	}

	.btnAnuncioMaps {
		display: inline-block;
		margin: 0.1em 0;
		border: 0.16em solid rgba(255, 255, 255, 0);
		box-sizing: border-box;
		text-decoration: none;
		font-family: "Roboto", sans-serif;
		font-weight: 300;
		color: black;
		text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
		text-align: center;
		transition: all 0.2s;
		background-color: #ffe521;
		font-size: 10pt;
	}

	/* write by ismile */
	.big-top-header {
		display: flex;
	}

	/* write by ismile end */

	/* MODIFIED_BY@KH start */
	.heroOperationContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}

	.presentacion {
		width: 45%;
		display: flex;
		align-items: center;
	}

	#form-home {
		width: 30%;
	}

	.presentacion div:nth-child(2) {
		width: 70%;
	}

	.btnBuscarHome {
		height: calc(22px + 2vmin);
	}

	/* .presentacion {
	position: absolute;
	display: flex;
	gap: 0px;
	left: 25%;
	top: 42%;
	font-size: 6pt;
	z-index: 10;
	}
	.presentacion .ComboPrincipalSelect {
	width: 10vw;
	z-index: 2 !important;
	}
	.presentacion div:nth-child(2) {
	width: 25vw;
	}
	.btnBuscarHome {
	position: static;
	height: calc(22px + 2vmin);
	}*/



	/* MODIFIED_BY@KH end */

	.Header2 {
		height: calc(50px + 2vmin);
		padding: 15px;
	}

	#topmerkasanew {
		display: flex;
		list-style: none;
		padding: 0 0 10px 0;
		height: 410px;
		width: 1250px;
		overflow: hidden;
		scroll-behavior: smooth;
	}

	#topmerkasanew .toplinew {
		float: left;
		padding: 0 0 0 0;
		-moz-box-shadow: 2px 4px 4px #9c9c9c;
		-webkit-box-shadow: 2px 4px 4px #9c9c9c;
		box-shadow: 2px 4px 4px #9c9c9c;
		margin: 15px 15px 15px 15px;
		border-top-right-radius: 10px;
		border-bottom-left-radius: 10px;
		cursor: pointer;
	}

	/* MODIFIED_BY@KH start */
	.slider-busqueda {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		background: #ffffff;
		width: 100%;
		height: 440px;
		/* overflow: scroll; */
		overflow-y: scroll;
		scroll-behavior: smooth;

		padding-left: 0;
		gap: 32px;
	}

	/* MODIFIED_BY@KH end */
	.slider {
		display: flex;
		gap: 10px;
		flex-direction: row;
		background: #ffffff;
		width: 100%;
		height: 400px;
		overflow: hidden;
		scroll-behavior: smooth;
		padding-left: 0 !important;
	}

	.slide-busqueda {
		margin: 20px 0;
		width: 300px !important;
		list-style: none;
		overflow: hidden;
	}


	#topmerkasa .topli {
		padding: 0 0 0 0;
		-moz-box-shadow: 2px 4px 4px #9c9c9c;
		-webkit-box-shadow: 2px 4px 4px #9c9c9c;
		box-shadow: 2px 4px 4px #9c9c9c;
		margin: 15px 15px 15px 15px;
		border-top-right-radius: 10px;
		border-bottom-left-radius: 10px;
		cursor: pointer;
	}

	#topmerkasa .topAnunciosAgente {
		padding: 0 0 0 0;
		-moz-box-shadow: 2px 4px 4px #9c9c9c;
		-webkit-box-shadow: 2px 4px 4px #9c9c9c;
		box-shadow: 2px 4px 4px #9c9c9c;
		margin: 15px 15px 15px 15px;
		border-top-right-radius: 10px;
		border-bottom-left-radius: 10px;
		cursor: pointer;
	}

	#topmerkasa .topagente {
		float: left;
		padding: 0 calc(10px + 2vmin) 0 calc(10px + 2vmin);
		/*margin: 0 0 0 20px;*/
		height: calc(430px + 2vmin);
	}

	#topmerkasa .topagente2 {
		float: left;
		border-top-right-radius: 10px;
		border-bottom-left-radius: 10px;
		width: 33.33%;
		display: block;
		height: 440px;
		margin: 2vw 0 0 0;
	}

	#topmerkasa .topAgenteDetalle {
		position: relative;
		list-style: none;
		right: 5px;
	}

	#topmerkasa .topproyectos {
		float: left;
		padding: 0 0 0 0;
		position: relative;
		margin: 15px 15px 15px 15px;
		border: none;
	}

	#topmerkasa .topbancos {
		float: left;
		padding: 0 0 0 0;
		position: relative;
		margin: 15px 15px 15px 15px;
		border: none;
	}

	.arrow {
		border: solid black;
		border-width: 0 3px 3px 0;
		display: inline-block;
		cursor: pointer;
		height: 20px;
		width: 19px;
		-moz-box-shadow: 2px 4px 4px #9c9c9c;
		-webkit-box-shadow: 2px 4px 4px #9c9c9c;
		box-shadow: 2px 4px 4px #9c9c9c;
	}

	.right {
		position: relative;
		right: 5px;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
	}

	.left {
		position: relative;
		left: 5px;
		transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
	}

	.right-agente {
		position: relative;
		bottom: 230px;
		left: 96vw;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
	}

	.left-agente {
		position: relative;
		left: 30px;
		top: 230px;
		transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
	}

	.arrow2 {
		border: solid black;
		border-width: 0 3px 3px 0;
		display: inline-block;
		padding: 3px;
		cursor: pointer;
		height: 17px;
		width: 17px;
	}

	.abajo {
		transform: rotate(-316deg);
		-webkit-transform: rotate(-316deg);
	}

	.imglist {
		list-style: none;
		margin: 0;
		padding: 0 0 0 0;
		display: inline-flex;
		overflow-x: auto;
	}

	.imglist ul {
		overflow-y: auto;
		height: 50%;
	}

	.imglist li {
		float: left;
		padding: 0 0 0 0;
		position: relative;
		-moz-box-shadow: 2px 4px 4px #9c9c9c;
		-webkit-box-shadow: 2px 4px 4px #9c9c9c;
		box-shadow: 2px 4px 4px #9c9c9c;
		margin: 15px 15px 15px 15px;
		border-top-right-radius: 10px;
		border-bottom-left-radius: 10px;
	}

	.divPrecioTop {
		background-color: #ffe521;
		border-radius: 10px;
		width: 50%;
		height: 30px;
		color: black;
		font-weight: bolder;
		padding-left: 5px;
		margin-left: 10px;
	}

	.divPrecioTop2 {
		background-color: #4d4c4c;
		border-radius: 10px;
		width: 50%;
		height: 30px;
		color: white;
		font-weight: bolder;
		padding-left: 5px;
	}

	.divPrecioTopNew {
		background-color: #ffe521;
		border-radius: 5px;
		height: 35px;
		color: black;
		font-weight: bolder;
		width: 200px !important;
		line-height: 35px;
		padding: 0 0 0 5px;
		max-width: 100%;
		font-size: 12pt;
		transform: translate(0, -15%);
		white-space: nowrap;
	}

	.divPrecioTopNewAnuncio {
		background-color: #ffe521;
		border-radius: 5px;
		height: 35px;
		color: black;
		font-weight: bolder;
		padding: 9px 0 0 5px;
		width: 13em;
		max-width: 100%;
		font-size: 10pt;
		transform: translate(0, -15%);
	}

	.divPrecioTopNewInteresados {
		background-color: #ffe521;
		border-radius: 10px;
		height: 35px;
		color: black;
		font-weight: bolder;
		padding-left: 10px;
		padding-top: 9px;
		width: calc(6em + 6vw);
		font-size: 8pt;
		text-align: left;
	}

	.divPrecioTopNew2 {
		position: relative;
		border-radius: 5px;
		height: 30px;
		color: black;
		font-weight: bolder;
		text-align: left;
		font-size: 10pt;
		padding-top: 8px;
		padding-left: 5%;
		font-size: 8pt;
		white-space: nowrap;
	}

	.divPrecioTopNewDetails {
		background-color: #ffe521;
		border-radius: 10px;
		height: 35px;
		color: black;
		font-weight: bolder;
		text-align: left;
		font-size: 14pt;
		padding-top: 8px;
		padding-left: 5px;
		width: 45vw;
		max-width: 11em;
		line-height: 25px;
	}

	.divPrecioTopNewUsuario {
		position: relative;
		left: 10px;
		background-color: #ffe521;
		border-radius: 10px;
		height: 25px;
		color: black;
		font-weight: bolder;
		font-size: 12pt;
		padding-top: 6px;
		line-height: 17px;
		width: 13em !important;
	}

	.divPrecioTopNew3 {
		background-color: #ffe521;
		border-radius: 10px;
		height: 35px;
		color: black;
		font-weight: bolder;
		padding-left: 10px;
		padding-top: 9px;
		width: calc(4.5em + 4.5vw);
		font-size: 6pt;
		text-align: left;
	}

	.divPrecioTopNew4 {
		display: flex;
		background-color: #ffe521;
		border-radius: 10px;
		height: 23px;
		color: black;
		font-weight: bolder;
		padding-left: 10px;
		width: 110px;
		font-size: 10pt;
		text-align: left;
	}

	.divAgenteTopNew {
		background-color: #ffe521;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		height: 30px;
		color: black;
		font-weight: bolder;
		font-size: 14pt;
	}

	.divAgenteTopNew2 {
		background-color: #ffe521;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		height: 1.5em;
		width: 11em;
		color: black;
		font-weight: bolder;
		font-size: 10pt;
		padding-left: 5px;
	}

	.Novedades1 {
		background-color: #f1efef;
		border-radius: 5px;
		color: black;
		font-size: 8pt;
		height: calc(8em + 8vw) !important;
	}

	.Novedades1 p {
		margin: 15px 0 calc(10px + 2vmin) 0;
	}

	.Novedades1 h2 {
		font-weight: bold;
	}

	.Novedades2 {
		background-color: #ffe521;
		border-radius: 5px;
		color: black;
		font-size: 8pt;
		height: calc(8em + 8vw) !important;
		font-weight: bold;
	}

	.Novedades2 h2 {
		font-weight: bold;
	}

	.btn {
		width: 140px;
		height: 28px;
		margin-top: 1px;
	}

	.btn-primary2 {
		font-size: 6pt !important;
		color: #ffe521 !important;
		background-color: #535353 !important;
		padding: 0 0 0 0 !important;
	}

	.btn-primary3 {
		font-size: 8pt !important;
		color: #ffe521 !important;
		background-color: #535353 !important;
		padding: 0 0 0 0 !important;
	}

	.btn-primary4 {
		font-size: 6pt !important;
		color: black !important;
		background-color: #ffe521 !important;
		padding: 0 0 0 0 !important;
	}

	.btn-primary5 {
		font-size: 6pt !important;
		color: black !important;
		background-color: transparent !important;
		padding: 0 0 0 0 !important;
		border: 1px solid #9c9c9c !important;
	}

	.btn-primary6 {
		font-size: 7pt !important;
		color: #ffe521 !important;
		background-color: #000 !important;
		padding: 0 0 0 0 !important;
	}

	.btnPromocionate {
		font-size: 14pt !important;
		color: black !important;
		background-color: #ffe521 !important;
		border: none;
		border-radius: 10px;
		width: 250px !important;
		height: 40px;
	}

	.LinkLoginRegistro {
		font-size: 7pt !important;
	}

	.btnGuardarAnuncio {
		font-size: 10pt !important;
		color: #ffe521 !important;
		background-color: #535353 !important;
		border-radius: 5px;
		border: none;
		height: 25px;
		width: 15em;
	}

	.btnVerTodas {
		color: #ffe521 !important;
		background-color: #535353 !important;
		border-radius: 50px;
		border: none;
		height: 3em;
		width: 10em;
	}

	.btnVerInteresados {
		position: absolute;
		color: #ffe521 !important;
		background-color: #535353 !important;
		border-radius: 50px;
		border: none;
		height: 2em;
		width: 10em;
		bottom: 10px;
		left: 29%;
	}

	.btnVerTodasInmobiliario {
		font-size: 8pt !important;
		color: #ffe521 !important;
		background-color: #535353 !important;
		border-radius: 10px;
		border: none;
		height: 25px;
		width: calc(5em + 5vw);
		position: absolute;
		right: calc(27em + 27vw);
		bottom: calc(-0.5em + -0.5vw);
	}

	.btnVerTodasBancos {
		font-size: 8pt !important;
		color: #535353 !important;
		background-color: #ffe521 !important;
		border-radius: 10px;
		border: none;
		height: 25px;
		width: calc(5em + 5vw);
		position: absolute;
		right: calc(27em + 27vw);
		bottom: calc(-0.5em + -0.5vw);
	}

	.btn-login {
		width: calc(30em+1vw) !important;
		height: calc(5em) !important;
	}

	.btn-login-right {
		width: 30em !important;
		height: 3.5em !important;
	}

	.btnEliminarAnuncio {
		font-size: 8pt !important;
		color: #ffe521 !important;
		background-color: #535353 !important;
		border-radius: 10px;
		border: none;
		height: 25px;
		width: 110px;
	}

	.checkboxcaracteristicas {
		flex-direction: row;
		font-size: 12pt;
		color: green;
	}

	#listaAnuncios {
		list-style: none;
		margin: 0;
		padding: 0 0 0 0;
	}

	#listaAnuncios li {
		float: left;
		padding: 0 0 0 0;
		position: relative;
		-moz-box-shadow: 1px 1px 1px 1px #9c9c9c;
		-webkit-box-shadow: 1px 1px 1px 1px #9c9c9c;
		box-shadow: 1px 1px 1px 1px #9c9c9c;
		border-bottom: 1px solid #535353;
		margin: 0 0 10px 0;
		border-color: #535353;
		border-radius: 5px;
		width: 98%;
		height: 100%;
	}

	#listaAnuncios li p {
		font-size: 8pt;
		text-align: justify;
		text-justify: inter-word;
		padding-left: 10px;
		color: black;
	}

	#listaAnunciosf {
		list-style: none;
		position: relative;
		margin-right: 5px;
		padding: 0 0 0 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		width: 100%;
		z-index: 1;
		overflow-y: hidden;
	}

	#listaAnunciosf .anunciosli {
		position: relative;
		-moz-box-shadow: 2px 4px 4px #9c9c9c;
		-webkit-box-shadow: 2px 4px 4px #9c9c9c;
		box-shadow: 2px 4px 4px #9c9c9c;
		border-top-right-radius: 10px;
		border-bottom-left-radius: 10px;
		margin-bottom: 4%;
		width: 230px;
		cursor: pointer;
		height: 280px;
	}

	.imagenlistanuncios {
		width: 250px;
		height: 170px;
		margin: 0 0 15px 0;
		border-radius: 10px;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	#listaAnuncio::-webkit-scrollbar {
		-webkit-appearance: none;
	}

	#listaAnuncio::-webkit-scrollbar:vertical {
		width: 10px;
	}

	#listaAnuncio::-webkit-scrollbar-button:increment,
	.contenedor::-webkit-scrollbar-button {
		display: none;
	}

	#listaAnuncio::-webkit-scrollbar:horizontal {
		height: 10px;
	}

	#listaAnuncio::-webkit-scrollbar-thumb {
		background-color: #797979;
		border-radius: 20px;
		border: 2px solid #f1f2f3;
	}

	#listaAnuncio::-webkit-scrollbar-track {
		border-radius: 10px;
	}

	.indicators {
		margin-top: -20px !important;
	}

	.indicators li {
		z-index: 5 !important;
	}

	.card-body {
		padding: 0px 0px 0px 0px !important;
	}

	.ToolTipDestacado {
		position: absolute;
		z-index: 2;
		text-align: center;
		background-color: #535353;
		color: #ffe521;
		font-size: 7pt;
		height: 6%;
		width: 22%;
		padding-top: 2%;
		border-bottom-left-radius: 5px;
	}

	.ToolTipCorazon {
		position: absolute;
		top: 0.5em;
		right: 0.1vw;
		z-index: 2;
		text-align: center;
		background-color: transparent;
		color: #ffe521;
		font-size: 7pt;
		height: 6%;
		width: 22%;
		padding-top: 2%;
		border-bottom-left-radius: 5px;
	}

	.ToolTipCorazonCardAnuncios {
		position: absolute;
		top: 0.2vw;
		left: 84%;
		z-index: 2;
		background-color: transparent;
		color: #ffe521;
		font-size: 7pt;
		height: 6%;
		width: 22%;
		padding-top: 2%;
		border-bottom-left-radius: 5px;
	}

	.DivTopAnuncios {
		width: 100%;
		height: 100%;
		float: left;
		position: relative;
		overflow-x: auto;
	}

	.DivTopAnunciosNew {
		width: 100%;
		height: 100%;
		float: left;
		position: static;
	}

	.DivAgentes {
		width: 100%;
		height: 100%;
		float: left;
		position: relative;
		padding: "0 0 0 0";
	}

	.DivAgentesTitulo {
		height: "calc(1em + 1vw)";
		position: relative;
		padding-bottom: "calc(2em + 2vw)";
	}

	.DivInmobiliario {
		width: 100%;
		background-color: #ffe521;
		height: calc(12em + 12vw);
	}

	.DivBancos {
		width: 100%;
		background-color: #535353;
		height: calc(12em + 12vw);
	}

	.FooterNew {
		background-color: black !important;
	}

	.FooterNew a {
		color: white !important;
	}

	#listaAnunciosfotos {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		margin: 0;
		padding: 0 0 0 0;
	}

	#listaAnunciosfotos2 .anuncioslif {
		float: left;
		padding: 30px 0 10px 0;
		position: relative;
		border-top-right-radius: 10px;
		border-bottom-left-radius: 10px;
		width: 25%;
	}

	#listaAnunciosfotos .anuncioslif2 {
		float: left;
		padding: 30px 0 0 0;
		position: relative;
		text-align: center;
		border-top-right-radius: 10px;
		border-bottom-left-radius: 10px;
		max-height: 30%;
		width: 33.33%;
	}

	.canvasf {
		object-fit: cover;
		width: 100%;
		height: 180px;
		border-radius: 10px;
	}

	.canvasperfil {
		object-fit: cover;
		width: 178px;
		height: 178px;
		border-radius: 10px;
		z-index: -1;
	}

	.bgperfil {
		border: 1px solid #9c9c9c;
		width: 178px;
		height: 178px;
		position: relative;
		border-radius: 10px;
		background-image: url("../src/img/face_no\ perfil.svg");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	#wimagen {
		height: auto;
		min-height: 300px;
		width: 100%;
		padding: 0 0 0 0;
		position: relative;
		-moz-box-shadow: 2px 4px 4px #9c9c9c;
		-webkit-box-shadow: 2px 4px 4px #9c9c9c;
		box-shadow: 2px 4px 4px #9c9c9c;
		border-top-right-radius: 10px;
		border-bottom-left-radius: 10px;
	}

	.imagenperfil {
		border-top: "1px solid #9c9c9c";
		bottom: "0px";
		height: "20px";
		width: "100%";
		position: "absolute";
		-moz-box-shadow: 2px 4px 4px #9c9c9c;
		-webkit-box-shadow: 2px 4px 4px #9c9c9c;
	}

	#wimagenagente {
		height: auto;
		min-height: 300px;
		width: 100%;
		padding: 0 0 0 0;
		position: relative;
	}

	#listaAnunciosfotos {
		display: flex;
		gap: 20%;
	}

	#listaAnunciosfotos2 {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		margin: 0;
	}

	.gallery-container {
		width: 100%;
		/*max-width: 1100px;
		margin-top: 60px;*/
	margin-bottom: 2vw;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: 400px;
	grid-gap: 1%;
}

.gallery-container .gallery__img {
	position: relative;
}

.gallery__title {
	position: absolute;
	bottom: 0;
	color: #535353;
	background-color: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 1));
	width: 100%;
	margin: 0;
	padding: 10px;
	text-align: left;
	font-size: 16pt;
	cursor: pointer;
}

.gallery-container .gallery__img figure {
	width: 100%;
	height: 100%;
}

.gallery-container .gallery__img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
	backface-visibility: hidden;
}

.gallery-container .gallery__img:nth-child(1) {
	grid-column-start: span 2;
}

.gallery__img figure:hover img {
	opacity: 1;
	-webkit-animation: flash 1.5s;
	animation: flash 1.5s;
}

@-webkit-keyframes flash {
	0% {
		opacity: 0.4;
	}

	100% {
		opacity: 1;
	}
}

@keyframes flash {
	0% {
		opacity: 0.4;
	}

	100% {
		opacity: 1;
	}
}

.btnEliminarFoto {
	width: 100% !important;
	float: center !important;
}

/* .divPrincipal */
/* { */
/*         width: 100%; */
/*         height: calc(2.2em + 2.2vw); */
/*         top: 0; */
/*         [>border: 1px solid black;<] */
/*         position: relative; */
/*         z-index: 3; */
/*         [>border-bottom: 1px solid #9c9c9c;<] */
/*         margin: 0; */
/*         padding: 0 0 0 0; */
/*         background-color: white; */
/* } */

.divPrincipalf {
	width: 100%;
	height: 70px;
	/* border: 1px dashed black;*/
	display: block;
	margin: 0;
	padding: 0 0 0 0;
}

.divPrincipal2 {
	width: 100%;
	height: 70px;
	/* border: 1px dashed black;*/
	display: block;
	margin: 0;
	padding: 0 0 0 0;
}

.divPrincipal .divPrincipalHijos {
	float: left;
	padding: 0 0 0 0;
	position: relative;
	height: 100%;
	width: 10%;
	margin-right: 5px;
	/* border: 1px dashed black;*/
}

.divPrincipalf .divPrincipalHijosf {
	float: left;
	padding: 0 0 0 0;
	position: relative;
	height: 100%;
	width: 10%;
	margin-right: 5px;
	/* border: 1px dashed black;*/
}

.divPrincipal2 .divPrincipalHijos2 {
	float: left;
	padding: 0 0 0 0;
	position: relative;
	height: 100%;
	width: 29.45%;
	margin-right: 5px;
	/* border: 1px dashed black;*/
}

.divPrincipalAnuncio {
	width: 100%;
	min-height: 515px;
	/*border: 1px solid black;*/
	margin: 0;
	padding: 0 0 0 0;
}

.divPrincipalAnuncio .divPrincipalHijosAnuncios {
	float: left;
	padding: 0 0 0 0;
	position: relative;
	height: 100%;
	width: 25%;
	/*border: 1px solid black;*/
	margin-top: 10px;
}

.divPrincipalAnuncio .divPrincipalHijosAnuncios2 {
	float: left;
	padding: 0 0 0 0;
	position: relative;
	min-height: 500px;
	width: 90%;
	/* border: 1px dashed black;*/
	margin-top: 10px;
}

.header {
	display: grid;
	grid-template: 50px / repeat(3, 33%);
	align-items: center;
	border-bottom: 1px solid #9c9c9c;
}

.divPrincipalHijo1 {
	display: flex;
	gap: 10px;
	grid-column: 1 / span 1;
	align-items: center;
	margin-left: 30px;
	overflow: hidden;
}

.divPrincipalHijo2 {
	grid-column: 2 / span 1;
	align-items: center;
}

.divPrincipalHijo3 {
	display: flex;
	gap: 10px;
	grid-column: 3;
	align-items: center;
	overflow: hidden;
	margin-left: 30px;
}

#header-publica {
	position: relative;
	z-index: 1;
}

.divPrincipalHijo1> :nth-child(1) {
	display: flex;
	width: 150px !important;
}

.divPrincipalHijo1> :nth-child(2) {
	display: flex;
	width: 150px;
}

.divPrincipalHijo1> :nth-child(3) {
	display: flex;
	width: 150px;
}

.logoMerkasa {
	width: 25%;
	height: 25%;
}

.divPrincipal .divPrincipalHijosHeader .divPrincipalNietosHeader {
	float: left;
	padding: 0 0 0 10px;
	position: relative;
	height: 100%;
	width: 33.33%;
	display: flex;
	font-size: 10pt;
}

.divPrincipal .divPrincipalHijos .divPrincipalNietos {
	float: left;
	padding: 0 0 0 0;
	position: relative;
	height: 100%;
	width: 33.5%;
	/* border: 1px dashed black;*/
}

.divPrincipalf .divPrincipalHijosf .divPrincipalNietosf {
	float: left;
	padding: 0 0 0 0;
	position: relative;
	height: 100%;
	width: 33.5%;
	/* border: 1px dashed black;*/
}

.divPrincipal2 .divPrincipalHijos2 .divPrincipalNietos2 {
	float: left;
	padding: 0 0 0 0;
	position: relative;
	height: 100%;
	width: 45%;
	/* border: 1px dashed black;*/
}

/*grid-column-start: span 2;*/

.divPrincipal .divPrincipalHijos:nth-child(2) {
	width: 25% !important;
}

.divPrincipal .divPrincipalHijos:nth-child(3) {
	width: 5% !important;
}

.divPrincipal .divPrincipalHijos:nth-child(1) {
	width: 5% !important;
}

.divPrincipal .divPrincipalHijos:nth-child(8) {
	width: 15% !important;
}

.divPrincipal .divPrincipalHijos:nth-child(9) {
	width: 5% !important;
}

/*lista*/
.divPrincipalf .divPrincipalHijosf:nth-child(2) {
	width: 25% !important;
}

.divPrincipalf .divPrincipalHijosf:nth-child(3) {
	width: 5% !important;
}

.divPrincipalf .divPrincipalHijosf:nth-child(1) {
	width: 5% !important;
}

.divPrincipalf .divPrincipalHijosf:nth-child(8) {
	width: 15% !important;
}

.divPrincipalf .divPrincipalHijosf:nth-child(9) {
	width: 5% !important;
}

/**/

.divPrincipal2 .divPrincipalHijos2:nth-child(1) {
	width: 5% !important;
}

.divPrincipal2 .divPrincipalHijos2:nth-child(5) {
	width: 5% !important;
}

.divPrincipal .divPrincipalHijos .divPrincipalNietos:nth-child(1) {
	width: 30% !important;
}

.divPrincipal .divPrincipalHijos .divPrincipalNietos:nth-child(2) {
	width: 50% !important;
}

.divPrincipal .divPrincipalHijos .divPrincipalNietos:nth-child(3) {
	width: 20% !important;
}

.divPrincipalf .divPrincipalHijosf .divPrincipalNietosf:nth-child(1) {
	width: 30% !important;
}

.divPrincipalf .divPrincipalHijosf .divPrincipalNietosf:nth-child(2) {
	width: 50% !important;
}

.divPrincipalf .divPrincipalHijosf .divPrincipalNietosf:nth-child(3) {
	width: 20% !important;
}

/*CAJAS HEADER*/
.divPrincipal .divPrincipalHijosHeader:nth-child(1) {
	width: 2% !important;
}

.divPrincipal .divPrincipalHijosHeader:nth-child(2) {
	width: 28% !important;
}

.divPrincipal .divPrincipalHijosHeader:nth-child(3) {
	width: 35% !important;
}

.divPrincipal .divPrincipalHijosHeader:nth-child(4) {
	width: 24% !important;
}

.divPrincipal .divPrincipalHijosHeader:nth-child(5) {
	width: 9% !important;
}

.divPrincipal .divPrincipalHijosHeader:nth-child(6) {
	width: 2% !important;
}

/*FIN CAJAS HEADER*/

.divPrincipalAnuncio .divPrincipalHijosAnuncios:nth-child(1) {
	width: 5% !important;
}

.divPrincipalAnuncio .divPrincipalHijosAnuncios:nth-child(4) {
	width: 5% !important;
}

.divPrincipalAnuncio .divPrincipalHijosAnuncios:nth-child(2) {
	width: 45% !important;
}

.divPrincipalAnuncio .divPrincipalHijosAnuncios:nth-child(3) {
	width: 45% !important;
}

.divPrincipalAnuncio .divPrincipalHijosAnuncios2:nth-child(1) {
	width: 5% !important;
}

.divPrincipalAnuncio .divPrincipalHijosAnuncios2:nth-child(3) {
	width: 5% !important;
}

.divPrincipal2 .divPrincipalHijos2 .divPrincipalNietos2:nth-child(2) {
	width: 10% !important;
}

.divHamburguesa {
	position: relative;
	left: 10px;
	cursor: pointer;
	width: 25px;
}

/*Estilos Agente*/

.ImagenAgenteRecuadro {
	border: 1px solid black;
	border-radius: 10px;
}

.ImagenAgente {
	width: calc(2.5em + 2.5vw);
	margin-left: 0px;
	margin-top: calc(0.5em + 0.5vw);
	border-radius: 10px;
	height: calc(3em + 3vw);
}

.CajasBotonesRedes {
	text-align: left;
	padding: 10px 0 0 0;
}



.ImgWspAgente {
	padding-top: calc(1em + 1vw);
	width: calc(7em + 7vw);
	height: calc(3em + 3vw);
	background-size: calc(7em + 7vw) calc(3em + 3vw);
	background-repeat: no-repeat;
	object-fit: contain;
	background-color: transparent;
	border: none;
}

.ImagenCorazon {
	height: 20px;
	margin-top: -15px;
}

.ImagenShared {
	height: 20px;
	margin-top: -15px;
}

.FormContactarAgente {
	border: 4px solid #d7d7d7;
	padding: calc(1em + 1vw);
	border-radius: 10px;
	height: "100%";
	width: calc(10em + 10vw);
}

.TituloSobreMi {
	text-align: "left";
}

.ComboPrincipalSelect {
	cursor: pointer;
	height: 36px;
	font-size: 9pt !important;
	font-weight: bold !important;
	border-radius: 0px !important;
	border: 0px !important;
	border-top-left-radius: 5px !important;
	border-bottom-left-radius: 5px !important;
}

.searchconfig {
	border-left: 3px solid #ffe521 !important;
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	border-top: 0px !important;
	border-right: 0px !important;
	border-bottom: 0px !important;
	height: 36px;
}

.divListaAnuncios {
	width: 100%;
	height: 50%;
	border: 1px solid black;
	display: inline-block;
}

.btnTipoPerfil:active {
	transform: scale(0.98);
	/* Scaling button to 0.98 to its original size */
	box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
	/* Lowering the shadow */
}

.divtipoperfil {
	z-index: 2;
	opacity: 0.5;
	background-color: #ffe521;
	border-radius: 10px;
	width: "100%";
	text-align: center;
}

/* #MisAvisosUsuario::-webkit-scrollbar { */
/*         -webkit-appearance: none; */
/* } */
/*  */
/* #MisAvisosUsuario::-webkit-scrollbar:vertical { */
/*         width:10px; */
/* } */
/*  */
/* #MisAvisosUsuario::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button { */
/*         display: none; */
/* }  */
/*  */
/* #MisAvisosUsuario::-webkit-scrollbar:horizontal { */
/*         height: 10px; */
/* } */
/*  */
/* #MisAvisosUsuario::-webkit-scrollbar-thumb { */
/*         background-color: #797979; */
/*         border-radius: 20px; */
/*         border: 2px solid #f1f2f3; */
/* } */
/*  */
/* #MisAvisosUsuario::-webkit-scrollbar-track { */
/*         border-radius: 10px;   */
/* } */

.BuscadorPrincipal {
	padding-bottom: 10px !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.ComboPrincipal {
	padding-bottom: 10px !important;
	padding-right: 0 !important;
}

.redirect-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	/* Fondo semitransparente */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	/* Asegura que el overlay esté en la parte superior */
}

/*******Fin Estilos Agentes*********/
.react-player {
	object-fit: cover !important;
	height: 350px !important;
	width: 100% !important;
	overflow: hidden !important;
}

.editor-descripcion {
	background-color: yellow;
	padding: 5px;
}

.wrapper-descripcion {
	border: 1px solid black !important;
}

.aviso-tarjeta {
	border: 1px solid #ccc;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	margin-bottom: 20px;
}

.aviso-tarjeta img {
	width: 100%;
	height: auto;
}

.contenido {
	padding: 20px;
}

.contenido h3 {
	margin-top: 0;
}

.boton {
	display: inline-block;
	padding: 8px 16px;
	background-color: #007bff;
	color: #fff;
	text-decoration: none;
	border-radius: 4px;
}

.boton:hover {
	background-color: #0056b3;
}

.editor-descripcion {
	height: 700px !important;
}

.anuncio-editor div.DraftEditor-root {
	height: auto;
	/* Establece la altura deseada */
	overflow-y: auto;
	/* Habilita el desplazamiento vertical si el contenido excede la altura */
}

div.DraftEditor-root {
	height: 300px;
	/* Establece la altura deseada */
	overflow-y: auto;
	/* Habilita el desplazamiento vertical si el contenido excede la altura */
}

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
	height: 100%;
	/* Asegura que el contenido ocupe toda la altura disponible */
}

/* MODIFIED_BY@KH start */
.icon-container {
	border: none;
	background: none;
}

.love-icon {
	font-size: 30px;
	color: #ff0000;
}

.icon-style {
	font-size: 36px;
	color: #ff0000;
}

.carousel-indicators {
	bottom: -16px !important;
}

.carousel-control-prev,
.carousel-control-next {
	opacity: 0.7 !important;
	border-radius: 50%;
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
	opacity: 1 !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	background-color: rgba(11, 11, 11, 0.2) !important;
	border-radius: 50%;
}
.botonesAnuncio {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px 30px;
	margin: 10px;
	font-size: 18px;
	font-weight: bold;
	color: #fff;
	border: none;
	border-radius: 5px;
	background-color: #4CAF50;
	cursor: pointer;
	transition: background-color 0.3s ease;
}
.botonesAnuncio::before {
	content: "";
	display: inline-block;
	margin-right: 10px;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
}
.botonesAnuncio-photos::before {
	content: "\f030"; /* Icono de cámara */
}
.botonesAnuncio-videos::before {
	content: "\f03d"; /* Icono de video */
}
.botonesAnuncio-photos {
	background-color: #4CAF50;
}
.botonesAnuncio-photos:hover {
	background-color: #45a049;
}
.botonesAnuncio-videos {
	background-color: #2196F3;
}
.botonesAnuncio-videos:hover {
	background-color: #1e88e5;
}
.slide-agente{
	height: 430px;
}

/* .modal-box .modal-content { */
/* background: #ffe521; */
/* } */


.modal-box .modal-header {
	border-bottom: 1px solid #000;
}

.modal-box .logoMerkasa-modal {
	width: 100px;
}

.modal-box .modal-text {
	font-size: 18px;
	font-weight: 500;
	text-align: center;
}

.detail-container {
	padding: 0 100px;
}
#button-login {
	font-size: 8pt !important;
}

.homeImage {
	position: relative;
	overflow: visible !important;
}

.emo-facebook {
	width: 200%;
	height: 100%;
}

.botones-header {
	border-radius: 10px !important;
	text-transform: capitalize !important;
	font-size: 10px !important;
	white-space: nowrap;
	backface-visibility: hidden !important;
	box-shadow: 0 0 0 0 !important;
	font-family: "calibri";
}

.actualiza-publicacion {
	position: relative;
	left: 22px;
}

.slider-project {
	display: flex;
	gap: 20px;
}

.slide-project {
	border-top-right-radius: 10px;
	border-bottom-left-radius: 10px;
	-moz-box-shadow: 2px 4px 4px #9c9c9c;
	-webkit-box-shadow: 2px 4px 4px #9c9c9c;
	box-shadow: 2px 4px 4px #9c9c9c;
	border-radius: 10px;
	margin: 0 0 0 0 !important;
	padding: 0 0 0 0 !important;
}

.terminos p,
ul li,
ol li {
	text-align: justify !important;
}

.terminos2 p,
ul li,
ol li {
	text-align: justify !important;
}

.vistos-favoritos {
	display: flex;
	flex-wrap: wrap;
	width: 90vw;
	gap: 30px;
}

.datosProyectos {
	position: relative;
	bottom: 18px;
}

.datosInmuebles {
	position: relative;
	bottom: 30px;
}

.datosAnuncios {
	position: relative;
	bottom: 18px;
}

#carousel-anuncios {
	width: 100% !important;
	color: black !important;
}

#carousel-anuncios .carousel-item {
	width: 100% !important;
}

.top_nav_header {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}
#carousel-anuncios .carousel-indicators {
	position: absolute;
	top: 90%;
}
.detailPage-carousel{
	width: 300px !important;
}
#precio-desde {
	position: relative;
	bottom: 33px;
	padding: 0 0 0 20px;
}

#fecha-project {
	position: relative;
	right: 10px;
}

#estado-project {
	position: relative;
	right: 20px;
}

.modal-datos-interesados {
	display: none;
}

#inmuebles-encontrados {
	position: relative;
	top: 3vh;
	left: 1vw;
}

#ordenar {
	position: relative;
	top: 15px;
	right: 55px;
}

#ordenar-opciones {
	position: relative;
	top: 10px;
	right: 50px
}
.slider .slide{
	width: 300px !important;
}
.mascara {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.6);
	height: 50%;
	width: 95%;
	top: 30%;
	left: 2%;
	border-radius: 20px;
	z-index: 2;
}

/* Reducir el tamaño de la "x" del InfoWindow */
.gm-style-iw-c {
	width: auto !important; /* Ancho más pequeño */
	height: auto !important; /* Altura más pequeña */
}
.botonMapaFiltros {
	position: relative;
	height: 30px;
	width: 150px;
	background-color: #ffd700; /* Fondo amarillo */
	color: #000; /* Texto negro */
	border: none;
	border-radius: 5px;
	padding: 15px 30px;
	font-size: 12px;
	font-weight: bold;
	margin: 10px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
}
.botonMapaFiltros::before{
	content: "";
	display: inline-block;
	margin-right: 5px;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;

}
.botonMapaFiltros-mapa::before{
	content: "\1f5fa";
}

.botonMapaFiltros-filtros::before{
	content: "🔬";
}
.botonMapaFiltros-lista::before{
	content: "🗒️";
}



/* .foto-portada{ */
/* border:5px solid green; */
/* } */

/*Aqui comienza el diseño responsivo*/


@media only screen and (max-width: 350px) {
	#letra-dormitorios-project {
		font-size: 7pt !important;
	}

	#letra-banio-project {
		font-size: 7pt !important;
	}

	#letra-const-project {
		font-size: 7pt !important;
	}

	.divTransaccionTopNew {
		font-size: 10pt !important;
	}

	#fecha-project {
		position: relative;
		right: 25px;
	}

	#estado-project {
		position: relative;
		right: 10px;
	}

	#card-anuncio-agente {
		width: 94vh;
		height: 520px;
	}

	.interesados_ul_li tr {
		font-size: 8pt;
	}

	.interesados_ul_li2 tr {
		font-size: 8pt;
	}
}
@media only screen and (max-width: 473px) {
	#listaAnunciosfotos2 .anuncioslif{
		width:100%;
		justify-content: center !important;
	}
	.carousel-control-prev-icon,
	.carousel-control-next-icon {
		background-color:unset !important;
	}
	#titulos-anuncios-row{
		flex-direction: row;
		justify-content: center !important;
		align-items: center !important;
		position: relative;
		bottom: 30px;
		width: 100vw;
		margin: 0 10px 0 10px;
	}

	#inmuebles-encontrados {
		position: relative;
		top: 0 !important;
		left: 85% !important;
		height: 20px;
		width: 100px;
	}
	#ordenar{
		position: relative !important;
		top: 30px !important;
		right: 90% !important;
	}
	#ordenar-opciones {
		position: relative !important;
		top: -60% !important;
		left: 20px !important;
		width: 10px !important;
	}
	.grid-imagenes{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
@media only screen and (max-width: 768px) {


	.detail-container {
		padding: 0 20px;
	}
	.listaAnunciosf2 .carousel-indicators {
		top: 170px !important;
	}
	/* MODIFIED_BY@KH end */

	.popupcarac.activo {
		height: calc(280px + 2vmin);
		width: calc(350px + 2vmin);
		top: calc(480px + 2vmin) !important;
		right: calc(-10px + 2vmin) !important;
	}

	.gallery-container {
		display: block !important;
	}

	.searchconfig {
		min-height: 100% !important;
	}

	.BuscadorPrincipal {
		padding-left: 10px !important;
		padding-right: 10px !important;
	}

	.ComboPrincipal {
		padding-right: 10px !important;
	}

	#listaAnunciosfotos .anuncioslif2 {
		width: 95% !important;
	}

	#wimagenagente {
		min-height: 400px !important;
	}

	#topmerkasa {
		display: flex;
		justify-content: center;
		list-style: none;
		margin: 0;
		padding: 0 0 0 0;
		overflow-x: auto;
	}



	#topmerkasanew .toplinew {
		float: left;
		padding: 0 0 0 0;
		-moz-box-shadow: 2px 4px 4px #9c9c9c;
		-webkit-box-shadow: 2px 4px 4px #9c9c9c;
		box-shadow: 2px 4px 4px #9c9c9c;
		margin: 15px 15px 15px 15px;
		border-top-right-radius: 10px;
		border-bottom-left-radius: 10px;
		cursor: pointer;
	}

	.divPrecioTopNewUsuario {
		width: calc(10em + 10vw) !important;
		margin: 0 0 0 0 !important;
		left: 0;
	}

	.editar-eliminar {
		display: flex;
		justify-content: center;
		position: relative;
		bottom: 30px;
	}

	.popupmenu {
		display: none;
	}

	.TituloSobreMi {
		text-align: "center" !important;
		width: 100% !important;
	}

	.FormContactarAgente {
		height: 100% !important;
		width: 80% !important;
	}

	.ImagenCorazon {
		height: calc(2em + 2vw) !important;
	}

	.ImagenShared {
		height: calc(2em + 2vw) !important;
	}


	.CajasBotonesRedes {
		text-align: center !important;
	}

	.ImagenAgenteRecuadro {
		border: none !important;
	}

	.ImagenAgente {
		width: calc(6em + 6vw);
	}

	.divPrincipal {
		height: calc(2.8em + 2.8vw) !important;
	}

	.divHamburguesa {
		padding-top: calc(0.4em + 0.4vw) !important;
		padding-left: calc(2em + 2vw) !important;
	}

	#topmerkasa .topagente {
		height: calc(25em + 25vw) !important;
	}

	.DivAgentesTitulo {
		padding-bottom: "calc(3em + 3vw)" !important;
	}

	.DivBancos {
		height: 100% !important;
	}

	.DivInmobiliario {
		height: 100% !important;
	}

	#topmerkasa .topagente2 {
		display: flex;
		flex-direction: row;
		justify-content: center;
		height: 515px !important;
		width: 100% !important;
		padding-bottom: calc(3.5em + 3.5vw);
	}

	.divPrincipal .divPrincipalHijos {
		width: 100% !important;
	}

	.divPrincipal .divPrincipalHijos .divPrincipalNietos:nth-child(1) {
		width: 100% !important;
	}

	.divPrincipal .divPrincipalHijos .divPrincipalNietos:nth-child(2) {
		width: 100% !important;
	}

	.divPrincipal .divPrincipalHijos .divPrincipalNietos:nth-child(3) {
		width: 100% !important;
	}

	.divPrincipal .divPrincipalHijos:nth-child(2) {
		width: 100% !important;
	}

	.divPrincipal .divPrincipalHijos:nth-child(3) {
		width: 100% !important;
	}

	.divPrincipal .divPrincipalHijos:nth-child(1) {
		display: none;
	}

	.divPrincipal2 .divPrincipalHijos2:nth-child(1) {
		display: none;
	}

	.divPrincipal2 .divPrincipalHijos2:nth-child(5) {
		display: none;
	}

	.divPrincipal2 .divPrincipalHijos2:nth-child(2) {
		width: 100% !important;
	}

	.divPrincipal2 .divPrincipalHijos2:nth-child(3) {
		width: 100% !important;
	}

	.divPrincipal2 .divPrincipalHijos2:nth-child(4) {
		width: 100% !important;
	}

	.divPrincipal .divPrincipalHijosHeader:nth-child(1) {
		display: none;
	}

	.divPrincipal .divPrincipalHijosHeader:nth-child(2) {
		display: none;
	}

	.divPrincipal .divPrincipalHijosHeader:nth-child(3) {
		width: 70% !important;
	}

	.divPrincipal .divPrincipalHijosHeader:nth-child(5) {
		width: 27% !important;
	}

	.divPrincipal .divPrincipalHijosHeader:nth-child(4) {
		display: none;
	}

	.divPrincipal .divPrincipalHijosHeader:nth-child(6) {
		display: none;
	}

	/*Lista y mapa*/
	.divPrincipalAnuncio .divPrincipalHijosAnuncios:nth-child(1) {
		display: none;
	}

	.divPrincipalAnuncio .divPrincipalHijosAnuncios:nth-child(4) {
		display: none;
	}

	.divPrincipalAnuncio .divPrincipalHijosAnuncios:nth-child(2) {
		width: 100% !important;
	}

	.divPrincipalAnuncio .divPrincipalHijosAnuncios:nth-child(3) {
		width: 100% !important;
	}

	.divPrincipalAnuncio .divPrincipalHijosAnuncios2:nth-child(1) {
		width: none;
	}

	.divPrincipalAnuncio .divPrincipalHijosAnuncios2:nth-child(3) {
		width: none;
	}

	.divPrincipalAnuncio .divPrincipalHijosAnuncios2:nth-child(2) {
		width: 100% !important;
	}

	#map {
		width: 100% !important;
		height: 100% !important;
	}

	.cardImagen{
	}
	.suggestion-container {
		top: 95px;
		right: 0;
	}

	.top_nav_header {
		display: block;
		width: 100%;
		margin-top: 5px;
	}

}
@media (max-width: 700px) {
	.card-anuncio-detalle{
		position: absolute;
		right:35px;
		width: 90vw !important;
	}
	.suggestion-container {
		width: 250px;
		position: absolute;
		top: 100%;
		left: 30%;
		z-index: 20;
		border-radius: 4px;
	}
	.slider .slide{
		width: 100% !important;
	}
	.btnBuscarHomeContenedor {
		position: absolute;
		display: flex;
		top: 80%;
		z-index: -2;
	}
	#topmerkasanew {
		list-style: none;
		margin: 0;
		height: 480px;
		padding: 0 0 0 0;
		overflow-x: auto;
	}
	.homeImage {
		top: 20px;
		height: calc(21.5em + 75vw) !important;
	}

	/* MODIFIED_BY@KH start */

	.heroOperationContainer {
		flex-direction: column;
		gap: 48px;
	}

	.presentacion {
		width: 100%;
		flex-direction: column;
		gap: 12px;
	}

	#form-home {
		width: 100%;
	}

	.presentacion div:nth-child(2) {
		width: 100%;
	}


	/* MODIFIED_BY@KH end */

	.arrow {
		display: none;
	}

	.slider {
		display: flex;
		width: 100%;
		scroll-snap-type: x mandatory;
		height: 430px;
		gap: 10%;
		overflow-x: auto;
		padding: 0 0 0 3% !important;
	}

	.slide {
		scroll-snap-align: center;
		flex: 0 0 100%;
		align-items: center;
		height: 500px !important;
	}


	.slide-project {
		flex: 0 0 100%;
		width: 100%;
		height: 100% !important;
		align-items: center;
		scroll-snap-align: center;
		overflow: "auto";
	}

	.slide-agente {
		flex: 0 0 100%;
		height: 455px;
		align-items: center;
		scroll-snap-align: center;
		overflow: "auto";
	}

	.slider-project {
		display: flex;
		width: 95% !important;
		scroll-snap-type: x mandatory;
		height: 500px;
		overflow-x: scroll;
	}

	/* MODIFIED_BY@KH start */
	.slider-agentes {
		gap: 0;
		width: 100%;
		height: auto;
		scroll-snap-type: x mandatory;
		overflow-x: scroll;
		margin: 0 0 0 0;
	}

	/* MODIFIED_BY@KH end */

	.cardAnuncios {
		height: 420px !important;
	}

	.cardImagen {
		position: relative;
		height: 240px !important;
	}

	.imagen-cards {
		width: 100% !important;
		height: 300px !important;
		border-radius: 0px;
	}

	.cardText {
		position: relative;
		top: 40px;
	}

	.cardAnuncioProyectos {
		width: 100vw !important;
		height: 300px !important;
	}

	.title-card-anuncio {
		height: 290px !important;
	}

	.cardTitleProyectos {
		width: 100vw !important;
		height: 300px !important;
	}

	.carousel {
		width: 100% !important;
	}

	#carousel-proyectos .carousel-inner {
		width: 100vw !important;
	}

	/* MODIFIED_BY@KH start */
	#carousel-proyectos .carousel-indicators {
		position: absolute !important;
		top: 273px !important;
		z-index: 2;
		color: blue;
	}

	/* MODIFIED_BY@KH end */

	#carousel-proyectos .carousel-control-prev-icon {
		position: absolute !important;
		bottom: 20px !important;
		background-color: red;
	}

	#carousel-proyectos .carousel-control-next-icon {
		position: absolute !important;
		top: 140px !important;
		right: 25px;
	}

	.gallery-container .gallery__img .gallery-container .gallery__img {
		position: relative;
		height: 80vw;
	}

	#perfilagente{
		position: relative;
		left:0;
		display: grid;
		grid-template-columns: 11em 14em;
		grid-template-rows: 3em 3em 3em 3em;
		gap: 1%;
	}

	.ImagenAgente {
		position: relative;
		bottom: 0;
		grid-column: 1;
		grid-row: 1/4;
		width: 10em;
		height: 10em;
		border: 1.5px solid black;
		border-radius: 10px;
	}

	.TituloAgente {
		position: relative;
		font-size: calc(1.5em + 1vw);
		grid-column: 2/2;
		grid-row: 1;
		text-align: left;
	}

	.divAgenteTopNew2 {
		position: relative;
		grid-column: 2/2;
		width: 15em;
		grid-row: 2;
		text-align: left;
		font-size: 10px;
	}

	.InfoCantInmuebles {
		position: relative;
		right: 10px;
		grid-column: 2/2;
		grid-row: 3;
		margin-left: 12px;
		font-size: 12px;
	}

	.ImagenCorazon {
		position: relative;
		grid-column: 2;
		grid-row: 4;
	}

	.LabelCantidadSeguidos {
		position: relative;
		grid-column: 2;
		grid-row: 4;
	}

	.ImagenShared {
		position: relative;
		grid-column: 3;
		grid-row: 4;
	}

	.nombre-apellido {
		position: relative;
		bottom: 8em;
		left: 7em;
	}

	.tipo-operacion-lupa2 {
		position: absolute;
		display: flex;
		top: 10vw;
		gap: 10px;
		z-index: 4;
	}

	/* MODIFIED_BY@KH start */
	.lupa {
		position: static;
		flex: 1;
		height: 41px;
		cursor: pointer;
		z-index: -1;
	}

	.contenedor-lupa {
		position: relative;
		z-index: 200;
	}

	.tipo-operacion {
		position: relative;
		width: 35vw !important;
		height: 41px;
		font-size: 12pt !important;
		z-index: -1;
		margin: 0 0 0 0 !important;
		padding: 0 0 0 10px !important;
		border-radius: 0px !important;
	}

	.txtBusqueda {
		position: none;
		font-size: 12pt !important;
		/* width: 45vw; */
		height: 41px;
		z-index: 1;
	}

	/* MODIFIED_BY@KH end */

	.txtBusquedaRegistro {
		position: relative;
		bottom: 5.7em;
		border: 5px solid #c9c5c5 !important;
		right: 8.8em;
		float: right;
		width: calc(4em + 20vw) !important;
		height: calc(2.5em + 8vw) !important;
		z-index: 8;
	}

	.mapa-filtros {
		display: flex;
		height: auto;
		gap: 16px;
		overflow: hidden !important;
		padding: 0 4px;
	}

	.mapa-pag {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 30%;
		height: 30px;
		z-index: 2;
		white-space: nowrap;
		line-height: 10px !important;
	}


	#dropdownMenuButton {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 2;
	}

	.sticky {
		position: fixed;
		top: 0;
		right: 52vw;
	}

	.mover-left {
		left: 50vw;
	}

	.fondo-blanco {
		position: fixed;
		cursor: pointer;
		top: 0;
		background-color: white;
		width: 100%;
		height: 40px;
		z-index: 1;
	}

	.fondo-transparente {
		height: 0;
		background-color: transparent;
	}

	.anuncios-mapa {
		position: absolute;
		top: 20% !important;
		padding: 0 0 0 0 !important;
		margin: 0 0 0 0 !important;
		width: 99% !important;
	}


	#inmuebles-encontrados {
		position:absolute;
		top: 15%;
		left: 60%;
		right: 0;
		font-size: 10pt !important;
		z-index: 10;
		white-space: nowrap;
		text-align: none !important;
		padding: 0 0 0 0 !important;
		margin: 0 0 0 0 !important;
		width: 170px;
	}
	#ordenar-container{
		position: absolute;
		left: 95% ;
		top: 5%;
	}
	#ordenar {
		position: absolute;
		right: 0;
		top: 15%;
		height: 30px;
		padding: 0 0 0 0 !important;
		z-index: 1;
	}

	#ordenar-opciones {
		position: absolute;
		right: 0px;
		top: 0;
		left: 100% ;
		width: 100px !important;
		height: 30px !important;
		z-index: 1;
		background-color: #b0b2b0 !important;

	}

	#listaAnuncio {
		position: relative;
		top: 7vh;
		padding: 0 0 0 0 !important;
		margin: 0 0 0 0 !important;
		height: auto;
		overflow: hidden;
	}

	.carousel-control-prev {
		position: absolute !important;
		top: -55% !important;
		/* color: black !important; */
	}

	.carousel-control-next {
		position: absolute !important;
		top: -55% !important;
		/* color: black !important; */
	}

	/* MODIFIED_BY@KH start  */
	.carousel-indicators {
		position: absolute !important;
		/* top: 35% !important; */
		top: 43% !important;
		z-index: 2 !important;
	}
	#carousel-proyectos .carousel-control-prev{
		position: absolute !important;
		top: 0% !important;
		bottom: 60% !important;
	}
	#carousel-proyectos .carousel-control-next {
		position: absolute !important;
		right: 8% !important;
		top: 0.5% !important;
	}
	#carousel-proyectos .carousel-indicators {
		position: absolute !important;
		right: 10%;
		z-index: 2 !important;
	}

	.detailPage-carousel .carousel-indicators {
		position: absolute !important;
		top: 35% !important;
		/* top: 43% !important; */
		z-index: 2 !important;
	}

	/* MODIFIED_BY@KH end  */

	.ToolTipCorazonCardAnuncios {
		position: absolute;
		top: 2vmin;
		left: 85%;
		z-index: 2;
		background-color: transparent;
		color: #ffe521;
		font-size: 7pt;
		height: 6%;
		width: 22%;
		padding-top: 2%;
		border-bottom-left-radius: 5px;
	}

	.card-anuncio {
		width: 280px;
		height: 380px !important;
	}

	.divPrecioTopNew2 {
		position: relative;
		font-size: 12pt;
		height: 40px;
		width: 40vw;
		margin: 0 0 20px 0;
	}

	.transaccion-inmuebles {
		font-size: 12pt !important;
		overflow-wrap: break-word;
	}

	#const-terreno {
		position: relative;
		top: 90px;
	}

	.linea {
		position: relative;
	}

	.footer {
		position: relative;
	}

	.transaccion {
		position: relative;
		right: 3em !important;
		top: 65px;
	}

	.popupcarac_ul li {
		padding: 0 0 0 0;
		position: relative;
		max-height: 50%;
		width: 40%;
		cursor: pointer;
	}

	#nosotros-cel {
		position: relative;
		top: 70px;
		padding-right: 10px;
		padding-left: 10px;
		width: 100% !important;
		text-align: justify;
	}

	.suggestion-item {
		position: relative;
		width: 100%;
		z-index: 20;
	}

	.suggestion-item2 {
		position: relative;
		z-index: 200;
	}

	.detalles {
		position: relative;
	}

	#Home {
		position: relative;
		width: 100% !important;
		overflow-x: hidden !important;
	}

	#ListaMapaAnuncios {
		width: 100% !important;
		overflow: hidden !important;
	}


	#listaDeAnuncios {
		/* display: flex; */
		position: absolute;
		top: 30%;
		grid-column: 1 / 4;
		grid-row: 3;
		display: grid;
		grid-template-columns: 56%;
		/* gap: 28px; */
		margin: 0 auto;
		overflow: scroll;
		/* padding: 0 0 0 0 !important; */
		/* padding: 0 20px !important; */
		padding: 0 0 0 0 !important;
		height: 100%;
	}

	#ListAgentes {
		width: 100%;
		overflow-x: hidden;
	}

	.row-info2 {
		position: relative;
		top: 90px;
	}

	/* MODIFIED_BY@KH start */
	/* .logoMerkasaClass {
	justify-content: center;
	position: relative !important;
	right: 95px !important;
	} */
	/* MODIFIED_BY@KH end */

	.servicios-vap {
		position: relative;
		bottom: 20px;
	}

	.cabecera-anuncio {
		position: relative;
		left: 0px;
		bottom: 0px;
	}

	.cardInteresados {
		width: 90% !important;
		height: 425px !important;
	}

	.card-interesados-contenedor {
		display: flex;
		justify-content: center;
	}

	.pagination .button {
		position: relative;
		top: 5px;
		height: 30px;
	}

	#misFotosPerfilAgente {
		position: relative;
	}


	.divBotonesRedes {
		display: flex;
		gap: 10px;
	}

	.BotonesRedes {
		width: 45px;
		height: 45px;
		background-size: 45px;
	}

	.fotos-agentes {
		left: 0px;
	}

	#topmerkasa .topAgenteDetalle {
		position: relative;
		list-style: none;
	}

	#listaAnunciosf {
		display: flex;
		flex-direction: row;
		width: 94vw !important;
		height: auto;
	}

	#listaAnunciosf .anunciosli {
		position: relative;
		-moz-box-shadow: 2px 4px 4px #9c9c9c;
		-webkit-box-shadow: 2px 4px 4px #9c9c9c;
		box-shadow: 2px 4px 4px #9c9c9c;
		margin: 0 0 8% 0;
		width: 90vw !important;
		height: 370px;
		cursor: pointer;
		overflow-x: hidden;
	}

	/* MODIFIED_BY@KH start */
	/* .divPrincipalHijo1 {
	height: 0;
	} */
	/* 
	.popupmenu2 {
	right: 50vw;
	left: 0;
	bottom: 30vw !important;
	} */

	.popupmenu2.activo {
		right: 50vw;
		left: 0;
		bottom: 40vw !important;
	}

	/* #header-hamburguesa {
	position: absolute;
	top: 5px;
	right: 75vw;
	}
	.divHamburguesa {
	width: 70px;
	} */

	#header-publica {
		position: absolute;
		top: 15px;
		/* left: 75vw; */
		left: 72vw;
		margin-right: 10px;
		/* max-width: 80px; */
		max-width: 86px;
	}

	/* MODIFIED_BY@KH end */

	#listaAnunciosfotos {
		justify-content: center;
	}

	#listaAnunciosfotos .anuncioslif {
		position: relative;
		width: 60%;
	}

	.logoMerkasa {
		width: 20vw;
		height: 20vw;
	}

	.btncaracteristicasanuncio {
		width: 120%;
		transform: translateX(0px);
	}

	.inmueble-trans {
		position: relative;
		font-size: 10pt;
	}

	/* .btnEliminarAnuncio */
	/* { */
	/*         font-size: 8pt !important; */
	/*         position: relative; */
	/*         top: 100px; */
	/*         color: #FFE521 !important; */
	/*         background-color: #535353 !important; */
	/*         border-radius: 10px; */
	/*         border: none; */
	/*         height: 25px; */
	/*         width: 100px; */
	/*  */
	/* } */

	.btnEditarAnuncio {
		position: relative;
		top: 105px;
		left: 50px;
		font-size: 8pt !important;
		color: #ffe521 !important;
		background-color: #535353 !important;
		border-radius: 10px;
		border: none;
		white-space: nowrap;
		height: 25px;
		width: 110px !important;
	}

	.btnEliminarAnuncio {
		position: relative;
		top: 105px;
		font-size: 8pt !important;
		color: #ffe521 !important;
		background-color: #535353 !important;
		border-radius: 10px;
		border: none;
		white-space: nowrap;
		height: 25px !important;
		width: 110px !important;
	}

	#visualizaciones {
		position: relative;
		bottom: 42px;
	}

	#fila-visualizaciones {
		position: relative;
		height: 10px;
		border-right: none !important;
	}

	#fila-valores {
		position: relative;
		height: 27px;
	}

	#col-valores {
		border-right: none !important;
	}

	#redes-sociales-footer {
		position: relative;
		left: 15px;
	}

	.circulos-registro {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		right: 14px;
	}

	.div-suggestion {
		right: 0px !important;
	}

	.caracteristicas-anuncio {
		padding: 0 0 0 0 !important;
	}

	.divPrecioTopNewDetails {
		font-size: 10pt;
	}

	.foto-anuncio-agente {
		width: 94vw !important;
		height: 200px !important;
	}

	.card-anuncio-agente {
		width: 90vw !important;
		height: 370px !important;
		padding: 0 0 0 0 !important;
		margin: 0 0 0 0 !important;
	}

	.card-title-agente {
		width: 98vw !important;
	}

	#dormitorio-project {
		width: 40%;
	}

	#banio-project {
		width: 40%;
	}

	#const-project {
		width: 20%;
	}

	.divPrecioTopNew {
		font-size: 12pt;
		padding: 0 0 0 3px !important;
	}

	.divTransaccionTopNew {
		font-size: 12pt !important;
	}

	#fecha-project {
		position: relative;
		right: 60px;
	}

	#estado-project {
		position: relative;
		right: 80px;
	}

	.divTransaccionTopNew2 {
		font-size: 10pt !important;
	}

	.lineaPropiedades {
		max-width: 70px !important;
	}

	#container-recientemente {
		margin: 20px 0 0 0 !important;
		padding: 0 0 0 5px !important;
	}

	.cardAnuncioVistos {
		height: 480px !important;
		padding: 0 0 0 0 !important;
	}

	.imgVisitados {
		height: 300px !important;
		width: 100% !important;
		border-radius: 0px !important;
		margin: 0 0 0 0 !important;
	}

	.cardAnuncio {
		display: flex;
		flex-direction: row;
		height: 450px !important;
		width: 90vw !important;
		padding: 0 0 0 0 !important;
		overflow: hidden !important;
	}

	.imgAvisos {
		height: 200px !important;
		width: 100% !important;
	}

	#containerAvisos {
		padding: 0 0 0 0 !important;
	}

	.redes {
		position: relative;
		width: 51%;
		display: flex;
		justify-content: left;
		align-items: center;
		padding-left: 3%;
	}

	.perfiles {
		display: none;
	}

	.registrocarac_ul li {
		width: 30%;
	}

	.carousel-interesados {
		width: 100vw !important;
	}

	#lista-interesados {
		display: none;
	}

	#card-interesados {
		height: 420px !important;
	}

	.modal-datos-interesados {
		height: 300px !important;
	}

	.interesados_ul_li .filas-interesados {
		display: flex;
		justify-content: center;
		font-size: 8pt;
	}

	.interesados_ul_li2 .filas-interesados {
		display: flex;
		justify-content: center;
		font-size: 8pt;
	}

	.botonAnterior {
		position: relative !important;
		top: 0px;
	}

	#carousel-anuncios {
		color: black !important;
		width: 100vw !important;
		height: 100% !important;
	}

	#MisAvisosUsuario {
		display: flex;
		height: auto !important;
		justify-content: center;
		flex-wrap: wrap;
	}

	.vistos-favoritos {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.cardVistos {
		width: 90vw !important;
	}

	#pages {
		position: relative;
		bottom: 40px;
	}

	.img-fluid {
		width: 100vw !important;
		position: relative;
		bottom: 90px;
		object-fit: contain !important;
	}

	#carousel-anuncios .carousel-indicators {
		position: absolute !important;
		top:440px !important;
	}

	#carousel-anuncios .carousel-control-prev {
		position: absolute !important;
		top: -21% !important;
	}

	#carousel-anuncios .carousel-control-next {
		position: absolute !important;
		top: -21% !important;
	}

	.listaAnunciosf2 {
		display: flex;
		justify-content: center;
		gap: 15px;
		width: 100%;
		padding-left: 0%;
	}
}
@media only screen and (max-width: 780px) {
	#listaAnunciosfotos2 .anuncioslif{
		width:33%;
	}


}
@media only screen and (max-width: 767px) {
	#listaAnunciosfotos2 .anuncioslif{
		width:50%;
		justify-content: center !important;
	}
	.cardAnuncios{
		width: 90vw !important;
	}

}

@media only screen and (max-width: 992px) {
	.detail-container {
		padding: 0 60px;
	}

	.slider-busqueda {
		display: flex;
		flex-wrap: wrap;
		width: 175%;
		height: auto;
		padding: 0 0 0 5px;
		overflow: scroll;
	}

	.slide-busqueda {
		margin: 0 0 20px 0 !important;
		width: 100%; 
		max-width: 400px;
	}
}
@media (max-width: 1024px) {

	/* #inmuebles-encontrados { */
	/* position: relative; */
	/* top: 40px; */
	/* left: 15vw; */
	/* } */
	.divPrincipalHijo1 {
		height: 0;
	}

	#listaDeAnuncios {
		/* display: flex; */
		grid-column: 1 / 4;
		grid-row: 3;
		display: grid;
		grid-template-columns: 100% 0%;
		/* gap: 28px; */
		overflow: hidden;
		/* padding: 0 0 0 0 !important; */
		padding: 0 20px !important;
		height: 100%;
	}

	#ListaMapaAnuncios {
		display:flex !important;
		width: 100% !important;
		overflow: hidden !important;
	}

	/* .contenedor-lupa {
	position: absolute;
	left: 89vw;
	z-index: 200;
	} */

	.mapa-filtros {
		/* position: relative; */
		display: flex;
		height: auto;
		width: 100vw;
		/* top: 0; */
		/* right: 10px; */
		/* background: #007bff; */
		/* width: 400px; */
		/* top: 9vh; */
		/* top: 9.5vh; */
		/* gap: 10px; */
		gap: 16px;
		overflow: hidden !important;
		justify-content: center;
		padding: 0 4px;
	}

	.popupmenu2 {
		right: 50vw;
		left: 0;
		bottom: 30vw !important;
	}

	.popupmenu2.activo {
		left: 2px;
		top: 52px;
	}

	#header-hamburguesa {
		position: absolute !important;
		top: 8px;
		right: 87vw;
	}

	.divHamburguesa {
		width: 40px;
		padding-top: 0 !important;
		padding-left: 0 !important;
	}

	.presentacion {
		width: 75%;
	}

	.right-agente {
		left: 94vw;
	}
	.mapa-filtros-modal {
		position: relative;
		display: flex;
		justify-content: center;
		gap: 10px;
		top: 5px;
	}
}


#cropper-container {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.crop-container {
	background-color: rgba(255, 255, 255, 0.229);
	position: relative;
	width: 100%;
	height: 320px;
	border-radius: 4px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	margin-top: 15px;
	padding-bottom: 40px;
}

.pagination {
	display: flex;
	position: relative;
	justify-content: center;
	flex-wrap: wrap;
}

.pagination .button {
	display: inline-block;
	margin: 5px;
	padding: 5px 10px;
	background-color: #ffffff;
	color: #000000;
	text-decoration: none;
	border: 1px solid #000000;
	cursor: pointer;
	border-radius: 4px;
}

.pagination .button.active {
	background-color: rgba(0, 0, 0, 0.6);
	color: white;
}



/* .mascara .TituloHome {
position: relative;
font-size: calc(2em + 1vw);
top: 10%;
text-align: center;
font-style: italic;
} */

	.mascara .TituloHome {
		/* position: relative; */
		font-size: calc(2em + 1vw);
		/* top: 10%; */
		text-align: center;
		font-style: italic;
		margin-top: 24px;
		margin-bottom: 48px;
	}

	/* MODIFIED_BY@KH end */

	#perfilagente{
		position: relative;
		left: 14vw;
		display: grid;
		grid-template-columns: 11em 15em;
		grid-template-rows: 2.5em 2.5em 2.5em 2.5em ;
		gap: 1%;
		margin-bottom: 20px;
	}

	.ImagenAgente {
		position: relative;
		bottom: 12px;
		border: 1.5px solid black;
		border-radius: 10px;
		width: 100%;
		height: 100%;
	}

	.divImagenAgente {
		grid-column: 1;
		grid-row: span 4;
	}


	.TituloAgente{
		grid-column: 2/2;
		grid-row: 1;
		text-align: left;

	}
	.divAgenteTopNew2{
		grid-column: 2/2; 
		grid-row: 2;
		text-align: left; 
	}
	.InfoCantInmuebles{
		grid-column: 2/2;
		grid-row: 3;
		text-align: left;
		margin-top: -10px;
	}


	.shacoraseguidores {
		position: relative;
		bottom: 10px;
		display: inline-flex;
		grid-column: 2/2;
		grid-row: 4;
	}

	.ImagenCorazon{
		position: relative;
		grid-column: 2;
		grid-row: 4;
	}

	.numeroCorazones {
		position: relative;
		bottom: 5px;
	}

	.ImagenShared {
		width: 70%;
		height: 70%;
	}

	#pages {
		display: flex;
		width: 100%;
		justify-content: center;
	}

	.card-anuncio {
		display: flex;
		position: relative;
		width: 290px;
		height: 350px !important;
	}

	#card-anuncio-agente {
		width: 300px;
		height: 350px;
		cursor: pointer;
		overflow: hidden;
	}

	.card-title-agente {
		height: 200px;
		width: 340px;
		border-radius: 10px;
	}

	#foto-anuncio-agente {
		width: 300px;
		height: 200px;
		object-fit: cover;
	}

	#nosotros {
		position: relative;
		display: grid;
		grid-template-columns: 2fr 6fr;
		grid-template-rows: 1fr 1.3fr 3fr;
		left: 18vw;
		column-gap: 30px;
		row-gap: 30px;
		padding-top: 50px;
	}

	#tituloMerkasa {
		text-align: center;
		padding-top: 70px;
	}

	.tipo-operacion-lupa {
		grid-column: 1;
		grid-row: 1;
		display: flex;
		gap: 0px;
		align-items: center;
		justify-content: left;
		padding: 10px 0 0 0;
	}

	.lupa {
		max-width: 50px !important;
		cursor: pointer;
		height: 35px;
		z-index: 1;
	}

	.txtBusqueda {
		position: relative;
		border: 1px solid #c9c5c5;
		font-size: 12px;
		height: 35px;
		z-index: 10;
	}

	.tipo-operacion {
		position: relative;
		left: 3px;
		height: 35px;
		width: 120px !important;
		font-size: 8pt !important;
		color: black;
		margin-left: 50px;
		z-index: 1;
		border-right: 0px !important;
	}

	/* #busqueda-filtros{ */
	/* display: grid; */
	/* gap: 50px; */
	/* grid-template-columns: 1fr 3fr; */
	/* grid-template-rows: 1fr; */
	/* align-items: center; */
	/* } */
	#filtros {
		grid-column: 2/4;
		grid-row: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 20px;
		margin: 0 20px 0 20px;
		/* align-items: center; */
		/* left: 35%; */
		/* top: 9%; */
	}

	.titulos-anuncios {
		position:relative;
		top: 35px;
		right: 240px;
		height: 70px;
	}

	/* MODIFIED_BY@KH start */
	#listaDeAnuncios {
		/* display: flex; */
		position: relative;
		grid-column: 1 / 4;
		grid-row: 3;
		display: grid;
		grid-template-columns: 55% 43%;
		/* gap: 28px; */
		overflow: hidden;
		/* padding: 0 0 0 0 !important; */
		padding: 0 20px !important;
		height: 450px;
	}

	/* MODIFIED_BY@KH end */

	#map {
		grid-column: 2;
		overflow: hidden;
		border-radius: 10px;
		height: 440px;
	}

	#ListaMapaAnuncios {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		/* grid-template-rows: minmax(10vh, auto) minmax(10vh, auto) 71vh; */
		overflow: hidden;
	}

	.suggestion-item {
		position: relative;
		width: 280px;
		z-index: 20;
		padding-top: 8px;
	}

	.suggestion-item2 {
		position: relative;
		width: 200px;
		top: 150px;
		right: 185px;
		z-index: 20;
	}

	/* MODIFIED_BY@KH start */


	.suggestion-box {
		padding: 6px;
	}

	/* MODIFIED_BY@KH end */

	#Home {
		position: relative;
		width: 100% !important;
		overflow-x: auto !important;
	}

	/* MODIFIED_BY@KH start */
	#logoMerkasa {
		/* position: relative; */
		width: 100px;
		height: 40px;
		/* left: 100px; */
	}

	/* MODIFIED_BY@KH end */

	.redes {
		position: relative;
		display: flex;
		flex-direction: Row;
		gap: 10px;
	}

	/* .hijosFiltro{ */
	/*         flex:1; */
	/* } */

	#topmerkasa-perfil-agente {
		display: inline-flex;
		list-style: none;
		overflow-x: auto;
		align-items: left;
	}

	#topmerkasa .topAnunciosAgente-perfil-agente {
		margin-bottom: 10px;
	}

	.listaAnunciosf2 {
		list-style: none;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		gap: 80px;
		/* width: 100%; */
		z-index: 1;
		overflow: hidden;
		/* padding: 0 0 0 0; */
		margin: 0 0 0 0;
		justify-content: center;
	}

	#listaAnunciosf2 {
		grid-column: 1;
		list-style: none;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		overflow-x: hidden;
		gap: 20px;
	}

	#listaAnunciosf2 .anunciosli {
		position: relative;
		margin-bottom: 8%;
		cursor: pointer;
		padding: 0 0 0 0;
	}

	.fotos-agentes {
		position: relative;
		display: flex;
		gap: 10px;
		flex-wrap: wrap;
		padding: 0 0 0 0;
	}


	.divBotonesRedes{
		display: flex;
		gap: 2%;
	}

	.BotonesRedes{
		width:calc(1.5em + 1.5vw);
		height: calc(2em + 2vw);
		background-size:calc(1.5em + 1.5vw) calc(2em + 2vw);
		background-repeat:no-repeat;
		background-color:transparent;
		border: none;
		cursor:pointer;
	}

	.circulos-registro {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	/* MODIFIED_BY@KH start */
	.slider-agentes {
		position: relative;
		display: flex;
		/* margin: 0 50px 0 50px; */
		/* gap: 100px; */
		padding-left: 0;
		scroll-behavior: smooth;
		/* overflow: auto; */
		overflow: hidden;
		height: 440px;
		justify-content: space-between;
	}

	/* MODIFIED_BY@KH end */

	.btnEditarAnuncio {
		font-size: 8pt !important;
		color: #ffe521 !important;
		background-color: #535353 !important;
		border-radius: 10px;
		border: none;
		white-space: nowrap;
		height: 25px !important;
		width: 110px !important;
	}

	#banio-project {
		position: relative;
		left: 10px;
	}

	.sugerencia-municipio {
		display: flex;
		direction: row;
		justify-content: space-between;
		padding: 10px;
		border-bottom: 1px solid #ddd;
	}

	.city {
		font-size: 12px;
		font-weight: bold;
	}

	.department {
		font-style: italic;
		color: #888;
		font-size: 8pt;
	}

	#autocomplete {
		width: 100%;
	}

	#barrioRegistro {
		position: relative;
	}

	.txtBarrio {
		position: relative;
		height: 31px;
	}

	.icons-redes {
		display: flex;
		gap: 10px;
	}

	#preloader_4 {
		position: relative;
	}

	#preloader_4 span {
		position: absolute;

		width: 20px;

		height: 20px;

		background: #3498db;

		opacity: 0.5;

		border-radius: 20px;

		-animation: preloader_4 1s infinite ease-in-out;
	}

	#preloader_4 span:nth-child(2) {
		left: 20px;

		animation-delay: 0.2s;
	}

	#preloader_4 span:nth-child(3) {
		left: 40px;

		animation-delay: 0.4s;
	}

	#preloader_4 span:nth-child(4) {
		left: 60px;

		animation-delay: 0.6s;
	}

	#preloader_4 span:nth-child(5) {
		left: 80px;

		animation-delay: 0.8s;
	}

	@keyframes preloader_4 {
		0% {
			opacity: 0.3;
			transform: translateY(0px);
			box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
		}

		50% {
			opacity: 1;
			transform: translateY(-10px);
			background: #f1c40f;
			box-shadow: 0px 20px 3px rgba(0, 0, 0, 0.05);
		}

		100% {
			opacity: 0.3;
			transform: translateY(0px);
			box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
		}
	}

	@media (min-width: 701px) {
		.suggestion-container {
			position: absolute;
			width: 250px;
			top: 17%;
			left: 13%;
			z-index: 5;
		}
	}


